import React from "react";
import PropTypes from "prop-types";

import JsComponents from "./sections/js-components.jsx";

import img7 from "../../assets/images/ui/memorybag-1.jpeg";
import img8 from "../../assets/images/ui/memorybag-2.jpeg";
import img9 from "../../assets/images/ui/memorybag-3.jpeg";

const MemoryBag = () => {
  const memorybagItems = [
    {
      src: img7,
      altText: "",
      caption: "",
    },
    {
      src: img8,
      altText: "",
      caption: "",
    },
    {
      src: img9,
      altText: "",
      caption: "",
    },
  ];
  return (
    <div id="main-wrapper">
      <div className="page-wrapper">
        <div className="container-fluid">
          <JsComponents
            id="memorybag"
            title="记忆背包"
            subtitle="简单、快速的保存你的每一次记忆"
            items={memorybagItems}
            isSupport={true}
            url="https://apps.apple.com/us/app/memory-bag-org-picture-video/id6608967106"
          />
        </div>
      </div>
    </div>
  );
};

MemoryBag.propTypes = {
  classes: PropTypes.object,
};

export default MemoryBag;
