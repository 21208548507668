import React from "react";
import { Container, Row, Col } from "reactstrap";
import { HashLink as Link } from "react-router-hash-link";

const CallToAction = () => {
  return (
    <div className="coming-soon" id="coming">
      <Container className="py-5 mt-5">
        <Row>
          <Col md="6">
            <div className="d-flex align-items-center">
              <div>
                <h2 className="title text-white font-weight-bold">
                  更多应用正在开发中~
                </h2>

                <Link
                  to="/#top"
                  className="btn btn-outline-light m-r-20 btn-md m-t-30 font-14"
                >
                  回到顶部
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CallToAction;
