import React from "react";
import "./assets/scss/style.scss";
import ReactDOM from "react-dom/client";
import { createBrowserHistory } from "history";
import { Route, Routes, HashRouter } from "react-router-dom";
import Components from "./views/components/components.jsx";
import MemoryBag from "./views/components/MemoryBag.jsx";
import MagicMoments from "./views/components/MagicMoments.jsx";
import AbbreviationAI from "./views/components/AbbreviationAI.jsx";
const root = ReactDOM.createRoot(document.getElementById("root"));

var hist = createBrowserHistory();
root.render(
  <HashRouter history={hist}>
    <Routes>
      <Route path="/" element={<Components />} />
      <Route path="/AbbreviationAI" element={<AbbreviationAI />} />
      <Route path="/MemoryBag" element={<MemoryBag />} />
      <Route path="/MagicMoments" element={<MagicMoments />} />
    </Routes>
  </HashRouter>
);
