import React from "react";
import PropTypes from "prop-types";

import JsComponents from "./sections/js-components.jsx";
import img1 from "../../assets/images/ui/abbr1.png";
import img2 from "../../assets/images/ui/abbr2.png";
import img3 from "../../assets/images/ui/abbr3.png";

const AbbreviationAI = () => {
  const abbrItems = [
    {
      src: img1,
      altText: "",
      caption: "",
    },
    {
      src: img2,
      altText: "",
      caption: "",
    },
    {
      src: img3,
      altText: "",
      caption: "",
    },
  ];

  return (
    <div id="main-wrapper">
      <div className="page-wrapper">
        <div className="container-fluid">
          <JsComponents
            id="abbr-ai"
            title="Abbreviation AI - 缩写查询AI 支持"
            subtitle="你是否被缩写困扰过吗？现在，你可以用AI帮你查询缩写背后的含义，也可以进行传统的查询"
            items={abbrItems}
            isSupport={true}
          />
        </div>
      </div>
    </div>
  );
};

AbbreviationAI.propTypes = {
  classes: PropTypes.object,
};

export default AbbreviationAI;
