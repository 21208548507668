import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Container,
  Row,
  Col,
} from "reactstrap";
import img1 from "../../../assets/images/features/feature48/app-store.png";
const JsComponents = ({ id, title, subtitle, items, isSupport, url }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  console.log(url);

  const slides = items.map((item) => (
    <CarouselItem
      className="custom-tag"
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item.src}
    >
      <img className="w-100" src={item.src} alt={item.altText} />
      <CarouselCaption
        captionText={item.caption}
        captionHeader={item.caption}
      />
    </CarouselItem>
  ));

  return (
    <div>
      {/* <div className="spacer" id={id}>
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">{title}</h1>
              <h6 className="subtitle">{subtitle}</h6>
            </Col>
          </Row>
        </Container>
      </div> */}
      <Container>
        <Row className="align-items-center">
          <Col md="6">
            <Carousel
              activeIndex={activeIndex}
              dark={true}
              next={next.bind(null)}
              previous={previous.bind(null)}
            >
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex.bind(null)}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous.bind(null)}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next.bind(null)}
              />
            </Carousel>
          </Col>
          <Col md="6">
            <h1 className="title font-bold">{title}</h1>
            <h6 className="subtitle">{subtitle}</h6>

            {isSupport && <h6 className="subtitle">QQ: 1992880030</h6>}
            {isSupport && (
              <h6 className="subtitle">Email: imjouch@gmail.com</h6>
            )}

            <a href={url} className="m-b-20">
              <img src={img1} alt="img" />
            </a>
            {/* <a href="#" className="m-b-20">
              <img src={img2} alt="img" />
            </a> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default JsComponents;
