import React from "react";
import { Container, Row, Col } from "reactstrap";

const HeaderBanner = () => {
  return (
    <div className="static-slider-head">
      <Container>
        <Row>
          <Col lg="8" md="6" className="align-self-center text-center">
            <h1 className="title">Hi! 我是jouch</h1>
            <h3 className="subtitle font-light">欢迎来到我的世界</h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderBanner;
